import { Api } from '../services/api.service';

export interface Banner {
  id: number;
  name: string;
  url: string;
  is_hidden: boolean;
  media_id: number;
  media_name: string;
  media_size: number;
  mobile_media_id: number;
  mobile_media_name: string;
  mobile_media_size: number;
}

export interface BannerSdk {
  getBanners(): Promise<Banner[]>;
  getBanner(id: number): Promise<Banner>;
  createBanner(data: CreateBannerData): Promise<Banner>;
  updateBanner(id: number, data: UpdateBannerData): Promise<Banner>;
  deleteBanner(id: number): Promise<void>;
  moveBannerUp(id: number): Promise<void>;
  moveBannerDown(id: number): Promise<void>;
}

interface CreateBannerData {
  name: string;
  url?: string;
  is_hidden?: boolean;
  media_id: number;
  media_hash: string;
  mobile_media_id: number;
  mobile_media_hash: string;
}

interface UpdateBannerData {
  name?: string;
  url?: string;
  is_hidden?: boolean;
}

export function createBannerSdk(api: Api): BannerSdk {
  return {
    async getBanners() {
      const data = await api.get('/banner');
      return data.banners;
    },
    async getBanner(id) {
      const data = await api.get(`/banner/${id}`);
      return data.banner;
    },
    async createBanner(data) {
      const result = await api.post('/banner', data);
      return result.banner;
    },
    async updateBanner(id, data) {
      const result = await api.patch(`/banner/${id}`, data);
      return result.banner;
    },
    async deleteBanner(id) {
      await api.delete(`/banner/${id}`);
    },
    async moveBannerUp(id) {
      await api.post(`/banner/${id}/up`);
    },
    async moveBannerDown(id) {
      await api.post(`/banner/${id}/down`);
    },
  };
}
